<template>
  <MDBCard class="m-3 form" :class="{ 'd-none':!Model }">
    <MDBCardBody>
      <form @submit="Submit">
        <MDBCardHeader v-if="(Action == 'insert')">{{ $t("FAQ.ADD-ITEM") }}</MDBCardHeader>
        <MDBCardHeader v-else-if="(Action == 'update')">{{ $t("FAQ.EDIT-ITEM") }}</MDBCardHeader>
        <MDBRow class="mb-3 mt-3 text-start" v-if="Action == 'insert'">
          <MDBCol >
            <h5> {{ $t("COMMON.SELECTLANGUAGE") }}</h5>
          </MDBCol>
        </MDBRow>
        <MDBRow class="mb-3" v-if="action == 'insert'">
          <div class="mb-3">
            <div>
              <label for="exampleFormControlInput1" class="form-label text-dark">{{ $t('COMMON.LANGUAGE') }}<span class="text-danger">*</span></label>  
            </div>
            <template v-for="(lang ,k) in languages" :key="k">
              <div class="form-check">
                <input class="form-check-input" type="radio" v-bind:id="`${Action}-lang-${lang.lang}`" :value="lang.lang" v-model="Form.lang.value">
                <label class="form-check-label" v-bind:for="`${Action}-lang-${lang.lang}`">
                  {{ $t(`LANG.${lang.lang}`) }}
                </label>
              </div>
            </template>
            <span class="text-danger">{{ langError }}</span>
          </div>
        </MDBRow>
        <MDBRow class="mb-3 mt-3 text-start">
          <MDBCol >
            <h5> {{ $t("COMMON.SETCATEGORY") }}<span class="text-danger">*</span></h5>
          </MDBCol>
        </MDBRow>
        <MDBRow class="mb-3">
          <MDBCol md="3">
            <select  class="form-select" v-model="Form.category.value">
              <option value="">{{ $t("COMMON.PLEASESELECT")}}</option>
              <template v-for="(item, k) in faqcategory" :key="k">
                <option :value="item.uuid">{{ item.name[Form.lang.value].name }}</option>
              </template>
            </select>
          </MDBCol>
          <span class="text-danger">{{ categoryError }}</span>
        </MDBRow>
        <MDBRow class="mb-3 mt-3 text-start">
          <MDBCol>
            <h5 >{{ $t("COMMON.SETCONTENT") }}</h5>
          </MDBCol>
        </MDBRow>
        <MDBRow class="mb-3" >
          <MDBCol md="12">
            <MDBRow class="g-3">
              <MDBCol md="12">
                <div>
                  <label class="form-label text-dark">{{ $t('COMMON.NAME') }}<span class="text-danger">*</span></label>
                  <input type="text" class="form-control" name="name" :placeholder="$t('COMMON.NAME')" v-model="Form.name.value">
                  <span class="text-danger">{{ nameError }}</span>
                </div>
              </MDBCol>
              <MDBCol md="12">
                <div>
                  <label class="form-label text-dark">{{ $t('COMMON.SORT') }}<span class="text-danger">*</span></label>
                  <input type="number" class="form-control" :placeholder="$t('COMMON.SORT')" v-model="Form.sort.value">
                  <span class="text-danger">{{ sortError }}</span>
                </div>
              </MDBCol>
              <MDBCol md="12">
                <div>
                  <label class="form-label text-dark">{{ $t('COMMON.CONTENT') }}<span class="text-danger">*</span></label>
                  <Ckeditor5 label="" v-model="Form.content.value"></Ckeditor5>
                  <span class="text-danger">{{ contentError }}</span>
                </div>
              </MDBCol>
            </MDBRow>
          </MDBCol>
        </MDBRow>
        <MDBBtn color="primary" type="submit"  v-if="action == 'insert'" :disabled="SubmitLoading">
          {{ $t("COMMON.INSERT") }}
          <span class="spinner-border Loading" :class="{ 'd-none':!SubmitLoading }" role="status" >
            <span class="visually-hidden">Loading...</span>
          </span>
        </MDBBtn>
        <MDBBtn color="primary" type="submit" v-if="action == 'update'" :disabled="SubmitLoading">
          {{ $t("COMMON.SAVE") }}
          <span class="spinner-border Loading" :class="{ 'd-none':!SubmitLoading }" role="status" >
            <span class="visually-hidden">Loading...</span>
          </span>
        </MDBBtn>
        <MDBBtn type="button" @click="Close">{{ $t("COMMON.CLOSE") }}</MDBBtn>
      </form>
    </MDBCardBody>
  </MDBCard>
</template>

<style scope>
  .category {
    list-style: none;
  }
</style>

<script>
import { MDBRow, MDBBtn, MDBCol, MDBCard, MDBCardBody, MDBCardHeader } from 'mdb-vue-ui-kit';
import ApiService from "@/core/services/api.service";
import CommonService from "@/core/services/common.service";
import { computed, inject, ref } from "vue";
import { useForm, useField } from 'vee-validate';
import * as yup from 'yup';
import { useI18n } from 'vue-i18n';
import i18nService from "@/core/services/i18n.service.js";
import Ckeditor5 from '@/components/Plugin/Ckeditor5';

export default {
  name: "FaqForm",
  components: {
    MDBRow,
    MDBBtn,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    Ckeditor5,
  },
  props: {
    action: {
      type: String,
      default: ""
    },
    modelValue: {
      type: Boolean,
      default: false
    },
    uuid: {
      type: String,
      default: ""
    },
    faqcategory: {
      type: [Array,Object],
      default: function(){
        return [];
      }
    }
  },
  setup(props, { emit }) {
    const Model = computed({
      get: () => props.modelValue,
      set: val => emit("update:modelValue", val)
    });
    const Action = computed({
      get: () => props.action,
      set: val => emit("update:action", val)
    });
    const FormUUid = computed({
      get: () => props.uuid,
      set: val => emit("update:uuid", val),
    });

    const GetTable = inject("GetTable");
    const i18n = useI18n();
    const languages = i18nService.languages;
    const SubmitLoading = ref(false);

    const basicSchema = {
      lang: yup.string().required().label(i18n.t("COMMON.LANGUAGE")),
      name: yup.string().required().label(i18n.t("COMMON.NAME")),
      category: yup.string().required().label(i18n.t("HEADER.FAQCATEGORY")),
      content: yup.string().required().label(i18n.t("COMMON.CONTENT")),
      sort: yup.number().required().label(i18n.t("COMMON.SORT")),
    };

    let ExtendSchema = {};
    const FormSchema = yup.object(Object.assign(basicSchema,ExtendSchema));

    const { handleSubmit, resetForm } = useForm({
      validationSchema: FormSchema,
      initialValues: {
        lang: i18nService.getActiveLanguage(),
        name: "",
        content: "",
        category: "",
        sort: 0,
      }
    });

    const { value: lang, errorMessage: langError } = useField('lang');
    const { value: category, errorMessage: categoryError } = useField('category');
    const { value: name, errorMessage: nameError } = useField('name');
    const { value: content, errorMessage: contentError } = useField('content');
    const { value: sort, errorMessage: sortError } = useField('sort');

    let Submit;

    if(Action.value == "insert") {
      Submit = handleSubmit(values => {
        SubmitLoading.value = true;
        ApiService.post("/Admin/v1/Faq", values).then(response => {
          SubmitLoading.value = false;
          if (response.status == 200) {
            CommonService.AxiosHandle(response);
            resetForm();
            Model.value = false;
            GetTable();
          }
        });
      });
    }else{
      Submit = handleSubmit(values => {
        SubmitLoading.value = true;
        ApiService.update("/Admin/v1/Faq",props.uuid ,values).then(response => {
          SubmitLoading.value = false;
          if (response.status == 200) {
            CommonService.AxiosHandle(response);
            resetForm();
            Model.value = false;
            FormUUid.value = "";
            GetTable();
          }
        });
      });
    }
    const Form = {
      lang,
      category,
      name,
      content,
      sort
    };

    const Close = function() {
      FormUUid.value = "";
      Model.value = false;
    };

    return {
      Action,
      Model,
      SubmitLoading,
      Form,
      langError,
      categoryError,
      nameError,
      contentError,
      sortError,
      Submit,
      resetForm,
      FormUUid,
      languages,
      Close,
    };
  },
  watch: {
    uuid: function(val){
      if(val){
        this.GetDetail();  
      }
    }
  },
  methods: {
    GetDetail() {
      this.resetForm();
      Promise.all([
        new Promise(resolve => {
          ApiService.get("/Admin/v1/Faq", this.FormUUid).then(response => {
            if (response.status == 200) {
              for (let k in response.data.data) {
                if(typeof this.Form[k] != "undefined" && response.data.data[k] != null) {
                  switch (k) {
                    case "category":
                      this.Form.category.value = response.data.data.category[0].uuid;
                    break;
                    default:
                      this.Form[k].value = response.data.data[k].toString();
                    break;
                  }
                }
              }
              this.Model = true;
              resolve(response);
            }
          });
        })
      ]);
    }
  }
};
</script>