<template>
	<Breadcrumbs></Breadcrumbs>
  <MDBCard class="m-3" :class="{ 'd-none':(add_dialog || edit_dialog)}">
    <MDBCardBody>
	<MDBContainer fluid class="mt-3">
    <transition name="fade">
      <PageLoading v-if="PageLoading"></PageLoading>
    </transition>
    <MDBRow>
      <MDBCol class="mb-2 text-start" md="2">
        <MDBSelect v-model:options="language.items" :label="$t('COMMON.LANGUAGE')" v-model:selected="language.selected"></MDBSelect>
      </MDBCol>
      <MDBCol md="2">
        <MDBInput :label="$t('COMMON.NAME')" :placeholder="$t('COMMON.NAME')" v-model="where.name" />
      </MDBCol>
      <MDBCol class="mb-2 text-end">
        <MDBBtn color="primary" @click="GetTable">{{ $t("COMMON.SEARCH") }}</MDBBtn>
        <MDBBtn color="info" @click="add_dialog = true">{{ $t("FAQ.ADD-ITEM") }}</MDBBtn>  
      </MDBCol>
    </MDBRow>
		<DataTable
			:header="headers"
			:tabledata="desserts"
			v-model:total="total"
			v-model:options="options"
			v-model:loading="loading"
		>
      <template v-slot:lang="{ item }">
        {{ $t("LANG." + item.lang) }}
      </template>
      <template v-slot:category="{ item }">
        {{ item.category[0].name[lang].name }}
      </template>
      <template v-slot:sub="{ item }">
        {{ item.category[0].upper.name[lang].name }}
      </template>
      <template v-slot:main="{ item }">
        {{ item.category[0].upper.upper.name[lang].name }}
      </template>
			<!-- 時間轉換 -->
      <template v-slot:created_at="{ item }">
        {{ Common.TimeFormat(item.created_at) }}
      </template>
      <!-- 操作template -->
      <!-- <template v-slot:uuid="{ item }">
				<a @click="EditUUid = item.uuid" data-bs-toggle="tooltip" :title="$t('FAQ.EDIT-ITEM')" href="javascript:void(0)" class="me-2">
					<MDBIcon icon="edit" iconStyle="fas" class="text-primary"/>
				</a>
				<a @click="DeleteAction(item.uuid)" data-bs-toggle="tooltip" :title="$t('FAQ.DELETE-ITEM')" href="javascript:void(0)" class="me-2">
					<MDBIcon icon="trash-alt" iconStyle="fas" class="text-danger"/>
				</a>
      </template> -->
      <template v-slot:edit="{ item }">
        <MDBBtn color="info" @click="EditUUid = item.uuid" class="px-3 py-2">
          <MDBIcon icon="edit" iconStyle="fas" class="text-white"/>
        </MDBBtn>
      </template>
      <template v-slot:uuid="{ item }">
        <MDBBtn color="danger" @click="DeleteAction(item.uuid)" class="px-3 py-2">
          <MDBIcon icon="trash-alt" iconStyle="fas" class="text-white"/>
        </MDBBtn>
      </template>
		</DataTable>
	</MDBContainer>
  </MDBCardBody>
  </MDBCard>
	<FaqForm v-model="add_dialog" action="insert" :faqcategory="FaqCategory"></FaqForm>
  <FaqForm v-model="edit_dialog" action="update" v-model:uuid="EditUUid" :faqcategory="FaqCategory"></FaqForm>
</template>

<script>
import { MDBContainer, MDBIcon, MDBBtn, MDBRow, MDBCol, MDBSelect, MDBCard, MDBCardBody, MDBInput } from 'mdb-vue-ui-kit';
import DataTable from '@/components/Plugin/DataTable';
import Breadcrumbs from '@/components/Breadcrumbs';
import FaqForm from '@/components/Form/Faq/FaqForm';
import ApiService from "@/core/services/api.service";
import CommonService from "@/core/services/common.service";
import i18nService from "@/core/services/i18n.service.js";
import PageLoading from '@/components/Elements/PageLoading';
import { ref, reactive } from "vue";
import { useI18n } from 'vue-i18n';


export default {
  name: "Faq",
  components: {
		DataTable,
		MDBContainer,
		MDBIcon,
		Breadcrumbs,
		FaqForm,
		MDBBtn,
    MDBRow,
    MDBCol,
    MDBSelect,
    MDBCard,
    MDBCardBody,
    PageLoading,
    MDBInput
  },
  setup() {
    const i18n = useI18n();
    const PageLoading = ref(true);
    const add_dialog = ref(false);
    const edit_dialog = ref(false);
    const loading = ref(true);
    const total = ref(0);
    const Common = CommonService;
    const EditUUid = ref("");
    const desserts = ref([]);
    const FaqCategory = ref([]);
    const lang = i18nService.getActiveLanguage();
    const language = reactive({
      items: i18nService.languages.map(item => { item.selected = (item.lang == i18nService.getActiveLanguage()); return item;}),
      selected: ""
    });

    const options = reactive({
      page: 1,
      itemsPerPage: 10,
      sortBy: ["sort"],
      sortDesc: [],
      groupBy: [],
      groupDesc: [],
      multiSort: false,
      mustSort: false
    });

    const where = reactive({
      name: "",
    });

    const headers = reactive([
      {
        text: i18n.t("COMMON.SEQUENCE"),
        align: "start",
        sortable: false,
        value: "no",class: "text-center",width:"50px"
      },
      { text: i18n.t("COMMON.LANGUAGE"), value: "lang" },
      { text: i18n.t("COMMON.CATEGORY"), value: "category" },
      { text: i18n.t("COMMON.NAME"), value: "name" },
      { text: i18n.t("COMMON.SORT"), value: "sort" },
      { text: i18n.t("COMMON.CREATE_AT"), value: "created_at" },
      { text: i18n.t("COMMON.EDIT"), value: "edit", sortable: false ,class: "text-center",width:"50px"},
      { text: i18n.t("COMMON.DELETE"), value: "uuid", sortable: false ,class: "text-center",width:"50px"}
    ]);

    const GetTable = function() {
      return new Promise(resolve => {
        loading.value = true;
        ApiService.query(
          "/Admin/v1/Faq",
          {
            params: Object.assign(CommonService.SortHandleObject(options),{lang:language.selected},where)
          }
        ).then(response => {
          if (response.status == 200) {
            desserts.value = CommonService.DataNoHandle(
              response.data.data.data,
              options.page,
              options.itemsPerPage
            );
            total.value = response.data.data.total;
            resolve(response);
          }
        });
      });
    };

    const GetCategory = function(show = true) {
      return new Promise(resolve => {
        loading.value = show;
        ApiService.query(
          "/Admin/v1/FaqCategory",
          {
            params: CommonService.SortHandleObject({
              page: 1,
              itemsPerPage: -1,
              sortBy: ["sort"],
              sortDesc: []
            })
          }
        ).then(response => {
          if (response.status == 200) {
            FaqCategory.value = response.data.data.data;
            loading.value = false;
            resolve(response);
          }
        });
      });
    };

    const DeleteAction = function(uuid) {
      CommonService.DeleteSwal().then(result => {
        if (result.isConfirmed) {
          ApiService.delete("/Admin/v1/Faq/" + uuid).then(
            response => {
              if (response.status == 200) {
                CommonService.AxiosHandle(response);
                GetTable();
              }
            }
          );
        }
      });
    };

		return {
      PageLoading,
      add_dialog,
      edit_dialog,
      loading,
      lang,
      total,
      Common,
      options,
      headers,
      desserts,
      EditUUid,
      language,
      GetTable,
      GetCategory,
      DeleteAction,
      FaqCategory,
      where
    };
  },
  mounted() {
    Promise.all([
      this.GetCategory()
    ]).then(() => {
      this.PageLoading = false;
    }).catch(error => {
      console.log(error);
    });
  },
  watch: {
		options: {
      handler() {
        this.GetTable();
      },
      deep: true
    },
    "language.selected": function(){
      this.GetTable();
    }
  },
  provide () {
    return {
      GetTable: this.GetTable
    }
  }
};
</script>